.shortcutsTableContainer {
  height: 100%;
  width: 400px;
  overflow: hidden;

  background-color: transparent;
  box-shadow: none;

  * {
    color: white;
  }

  .shortcutsTableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .shortcutsTable {
    height: 100%;
    overflow: hidden;
  }

  .columnLabels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid white;

    button.resetDefaultsButton {
      font-family: 'open-sans', sans-serif;
      background-color: #3f51b5;
    }
  }

  .shortcutsTableContent {
    height: 100%;
    overflow: scroll;
  }

  .keyboardShortcutRow {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid white;

    &:hover {
      cursor: pointer;
      background-color: #9b999945;
    }

    .keyboardAction {
      width: fit-content;
    }

    .assignedShortcut {
      width: fit-content;
      padding: 20px;
    }
  }
}

