.accountControls {
  margin-right: 15px;

  button {
    color: seashell;
  }

  .logInButton, .createAccountButton, .logOutButton {
    font-family: 'open-sans', sans-serif;
    text-transform: none;
  }

  .logOutButton {
    padding: 0;
    vertical-align: baseline;
  }

  .signedInUsernameLabel {
    font-size: 0.875rem; // just matching the font-size value from MUI on the logout Button
    color: seashell;
    font-family: 'open-sans', sans-serif;
  }
}