div.decklistDialog {
  background: #262339e3;
  color: #f0f0ff;
  padding: 25px;
  padding-top: 10px;
  border-radius: 12px;

  & * {
    font-family: 'open-sans', sans-serif !important;
  }

  .typeFilterInput {
    float: right;
  }
}

.decklistFilterControls {
  width: 100%;

  .sortSwitch {
    font-size: 14px;
    width: fit-content;
    display: inline-flex;
    float: right;

    .MuiSwitch-thumb {
      background-color: white;
    }

    .MuiSwitch-track {
      background-color: grey !important;
    }
  }

  span.MuiCheckbox-root {
    padding: 0;
  }

  .colorFiltersLabel {
    font-size: 14px;
    margin-right: 5px;
  }
}

.decklistDialogContent {
  width: 35vw;
  height: 45vh;
  padding: 0px 24px;
  padding-bottom: 24px;
  overflow-y: scroll;

  hr {
    width: 50%;
    margin: 0;
    margin-bottom: 10px;
  }

  .categoryHeader {
    display: block;
    margin-bottom: 5px;
  }

  ul:last-child {
    margin-bottom: 25px;
  }

  .subcategoryHeader {
    display: block;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  li {
    margin-bottom: 5px;
  }

  .itemQuantity {
    width: 40px;
    display: inline-block;
  }

  .itemName {
    text-transform: capitalize;
    display: inline-block;
  }
}