div.sideboardDialog {
  background: #262339e3;
  color: #f0f0ff;
  padding: 25px;
  padding-bottom: 0;
  border-radius: 12px;
  width: 650px;
  max-width: unset;

  & * {
    font-family: 'open-sans', sans-serif !important;
  }
}

.sideboardDialogContent {
  box-sizing: border-box;
  width: 100%;
  height: 65vh;
  padding: 20px 24px;

  li {
    margin-bottom: 5px;
  }

  .itemQuantity {
    width: 40px;
    display: inline-block;
  }

  .itemName {
    text-transform: capitalize;
    display: inline-block;
  }

  .unsavedChangesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}