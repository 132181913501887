div.rollDieDialog {
  background: #262339e3;
  color: #f0f0ff;
  border-radius: 12px;
  text-align: center;

  h2 {
    font-size: 1.5rem;
  }

  & * {
    font-family: 'open-sans', sans-serif !important;
  }
}

.rollDieDialogContent {
  width: 20vw;
  height: 25vh;
  padding: 0px 24px;
  max-width: 600px;
  box-sizing: border-box;

  li {
    margin-bottom: 20px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}