.transferListContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 600px;
  user-select: none;
  max-height: 40vh;
  overflow: hidden;

  .sideboardContainer, .decklistContainer {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 50%;

    .listTitle {
      text-decoration: underline;
    }
    
    .scrollableContainer {
      overflow: scroll;
      margin-right: 20px;
    }

    li {
      width: fit-content;

      .itemName:hover {
        cursor: pointer;
      }
    }
  }
}