.mobileSidebar {
  .MuiDrawer-paper {
    width: ~"max(30vw, 200px)";
    background-color: #1b1b3e;
    color: seashell;
  }

  .mobileMenuItem {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'open-sans';
    color: seashell;
    text-decoration: none;

    &:hover, &:active {
      background-color: #282855;
    }
  }

  .signedInUsernameLabel {
    text-align: center;
    &:hover, &:active {
      background-color: #282855;
    }
    button {
      color: seashell;
    }
  }

  button.logInButton {
    color: seashell;
    &:hover, &:active {
      background-color: #282855;
    }
  }
}