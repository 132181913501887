.decksList {
  color: #edebf2;
  font-family: 'open-sans', sans-serif;

  position: relative;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  max-width: calc(8 * 200px); // never more than 8 decks per row, even on ultrawide

  height: fit-content;
  max-height: 78vh;
  // min-width: 840px; // removing this because it made it so it wouldn't resize
  padding-top: 10px;
  padding-left: 25px;

  overflow-y: auto;
  overflow-x: hidden;

  &.emptyState {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .emptyStateMessage {
    font-size: 18px;
    
    height: fit-content;
    position: relative;
    top: 35%;

    .emptyStateMainLabel {
      margin-bottom: 8px;
    }
  }

  .emptyStateCreateButton {
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
    margin: auto;
  }
}