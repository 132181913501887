.cardInPlay {
  position: absolute;
  display: inline-block;
  height: 14vh;
  width: calc(14vh / 1.4);
  perspective: 1000px;

  transition-property: transform;
  transition-duration: 0.4s;
  transition-delay: 0s;
}

.tapped {
  transform: rotate(90deg);
}

.highlighted {
  transform: scale(1.4);
}

.highlighted.tapped {
  transform: scale(1.4) rotate(90deg);
}

.flipper {
  transition-property: left, bottom, transform;
	transition-duration: 0.6s;
	transform-style: preserve-3d;

  width: 100%;
  height: 100%;

	position: relative;
}

.flipped {
  transform: rotateY(180deg);
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;

  background-size: 100% 100%;
	position: absolute;
	top: 0;
	left: 0;

  // started needing this when we started using the `normal` image from scryfall instead of `png`
  // since it was no longer transparent corners
  border-radius: 5%;

  width: 100%;
  height: 100%;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
}

.faceDownCardInPlay {
  background-image: url("../../../../shared/card-back.png");
  background-size: 100% 100%;
}

.groupSelectedCard {
  box-shadow: 0 0 15px 0px #f9f9f9;
}

.highlighted {
  transform: scale(1.4);
}