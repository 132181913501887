.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100vw;
  max-width: 100%;
  height: 50px;

  color: seashell;
  background-color: #0e0e20;
  box-shadow: 0px 3px 13px #0000007d;

  position: relative;
  z-index: 999;
}

.mainLogo {
  height: 40px;
  display: inline-block;
  margin-left: 15px;

  @media screen and (max-width: 450px) {
    height: 30px;
  }
}