div.swapDeckDialogContainer { // need the "div." to exceed the specifity of the mui rules
  background: #262339e3;
  color: #f0f0ff;
  border-radius: 12px;
  overflow: visible;
  padding-left: 10px;
}

.swapDeckDialogTitle * {
  font-family: 'open-sans', sans-serif !important;
}

.swapDeckDialogTitle h2 {
  margin-bottom: 10px;
}

.swapDeckDialogContent {
  height: fit-content;
  max-width: 100%;
  padding: 0px 24px;
  font-family: 'open-sans', sans-serif;

  .deckSelector .rfs-select-container {
    width: 300px;
    margin-bottom: 20px;
  }

  #submit {
    display: block;
    margin-bottom: 30px;
  }
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}